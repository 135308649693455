import React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import BackgroundTelcoCloud from "../components/background/BackgroundTelcoCloud"
import ContactUs from "../components/ContactUs"

const Page = () => (
  <Layout>
    <PageHeader
      title="Telco Cloud Benchmarking"
      subtitle="Benchmark your Telco cloud using Voereir’s benchmarking tool, to analyse it's performance."
    />
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <div className="img-wrap box-bottom-left height-400 width-100">
              <BackgroundTelcoCloud />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Telco Cloud</h2>
            </div>
            <p className="paragraph-3">
            VoerEir offers Touchstone to Telco operators to facilitate the benchmark of multiple telco cloud environments (NFVI) spanning across multiple data centers.
            </p>
            <p className="paragraph-3">
            It provides support for benchmarking the Openstack or Kubernetes environments using an advanced test engine developed by VoerEir, including many popular open-source test engines.
            </p>
            <p className="paragraph-3">
             Touchstone provides a wide array of features for environment benchmarking, report management, and Enhanced Platform Aware (EPA) test suites.
             </p>
             <p className="paragraph-3">
              Touchstone is a leading-edge product in cloud environment test automation, benchmarking, certification, and validation.
            </p>
          </div>
        </div>
      </div>
    </section>

    <ContactUs />
  </Layout>
)

export default Page
